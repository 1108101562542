.hidden-menu-item {
    opacity: 0;
    transform: translateX(100%);
    animation: appearFromRight 0.5s forwards;
}

.hidden-menu-item:nth-child(1) {
    animation-delay: 0.75s;
}

.hidden-menu-item:nth-child(2) {
    animation-delay: 0.5s;
}

.hidden-menu-item:nth-child(3) {
    animation-delay: 0.25s;
}

.hidden-menu-item:nth-child(4) {
    animation-delay: 0s;
}

@keyframes appearFromRight {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}