@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0; /* Start hidden */
    animation: fadeIn 1s ease-in-out forwards; /* Run the fade-in animation */
    animation-delay: 0.1s; /* Delay the animation */
}